import React from 'react';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import "./App.css";
import abi from './abi';
import TokenABI from './tokenAbi';

function App() {

  const [currentAccount, setCurrentAccount] = useState(null);
  const [contractAddress, setContractAddress] = useState(null);
  const [fee, setFee] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [airdropAmount, setAirdropAmount] = useState(null);
  const [vipProgress, setVipProgress] = useState(0);
  const [vipLevel, setVipLevel] = useState("VIP");
  const [vipText, setVipText] = useState("Hold 1000 token to reach VIP level");


  const fetchData = async () => {
    try {
      const response = await axios.get('data.json');
      setContractAddress(response.data[0].value);
      setFee(response.data[1].value);
      setTitle(response.data[2].value);
      setDescription(response.data[3].value);
      setTokenContract(response.data[4].value);
      setTokenSymbol(response.data[5].value);
      setAirdropAmount(response.data[6].value);;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchBalance = async (accountAddress) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const tokenContract = new ethers.Contract("0x7c51084561b370e5D1Cf7D1697ff0D690607750f", TokenABI, provider);
    try {
      const balance = await tokenContract.balanceOf(accountAddress);
      // alert(balance / 1000000000000000000);
      checkLevel(balance / 1000000000000000000);
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  function checkLevel(holdAmount){
    if(holdAmount < 1000){
      setVipLevel("VIP");
      setVipText("Hold 1000 token to reach VIP level");
      setVipProgress(parseInt((holdAmount / 1000) * 100));
    }
    if(holdAmount >= 1000 && holdAmount < 5000){
      setVipLevel("Super VIP");
      setVipText("YOUR ARE VIP, Hold 5000 token to reach Super VIP level");
      setVipProgress(parseInt((holdAmount / 5000) * 100));
    }
    if(holdAmount >= 5000 && holdAmount < 10000){
      setVipLevel("Extra VIP");
      setVipText("YOUR ARE SUPER VIP, Hold 10,000 token to reach Extra VIP level");
      setVipProgress(parseInt((holdAmount / 10000) * 100));
    }
    if(holdAmount >= 10000){
      setVipLevel("Extra VIP");
      setVipText("YOUR ARE EXTRA VIP");
      setVipProgress(parseInt((holdAmount / 10000) * 100));
    }
  }

  const checkWalletIsConnected = () => {
    const { ethereum } = window;
    if(!ethereum){
      console.log("Metamask NOT Installed");
      return;
    }else{
      console.log("Metamask Installed");
    }
   }

  const connectWalletHandler = async() => { 
    const { ethereum } = window;
    if(!ethereum){
      alert("Please Install Metamask!");
    }

    try{
      const accounts = await ethereum.request({method: 'eth_requestAccounts'});
      console.log("Found an account :", accounts[0]);
      setCurrentAccount(accounts[0]);
      fetchBalance(accounts[0]);
    }catch (err){
      console.log(err);
    }
  }

  const connectWalletButton = () => {
    return (
      <button className="btn-claim" onClick={connectWalletHandler} tabindex="0" type="button">
        Wallet Connect
      </button>
    )
  }

  const airdropButton = () => {
    return (
      <button className="btn-claim" onClick={airdropHandler} tabindex="0" type="button">
        Claim Airdrop
      </button>
    )
  }

  useEffect(() => {
    checkWalletIsConnected();
    fetchData();
  }, [])

  
  const airdropHandler = async() => {
    try{
      const { ethereum } = window;

      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Intialize payment");
        let getadrp = await contract.dropTokens({value: fee});
        console.log(contract);
		if(getadrp){
			alert("Congratulations, you got our airdrop, you will receive your tokens very soon");
		}else{
			alert("Something wrong, Maybe you don't have enough balance for transaction fee or your wallet already have this token");
		}
      }
    }catch(err){
		alert("Something wrong, Maybe you don't have enough balance for transaction fee or your wallet already have this token");
    	console.log(err);
    }

  }


  return (
    <div className="div-self">
      <div className="card-s">
        <div className="card-header-s">
          <div className="flex-logo">
            <div className="logo-div">
              <img
                src="logo.png"
                className="company-logo"
                alt="Company Logo"
              />
            </div>
          </div>
          <div className="d-flex-auto">
            <span className="header-text-s">{title}</span>
          </div>
        </div>

        <div className="mt-15">
          <img
            className="banner-img"
            src="banner.png"
            height="200"
            alt="Banner"
          />
        </div>

        <div>
          <div className="text-center">
            <p className="para-s">
              {description}
            </p>
          </div>
          <div className="contract-div">
            <p>
              Contract :{" "}
              <span className="contract-font">
                {tokenContract}
              </span>
            </p>
          </div>
          <h6 className="claim-h">Claim : {airdropAmount} {tokenSymbol}</h6>

          {currentAccount ? airdropButton() : connectWalletButton()}

          <br />
          <br />
          <div className="contract-div">
            <p>
              <span className="contract-font">
                {vipLevel} Progress
              </span>
            </p>
            <div className="progress-bar">
                <div className="progress" style={{ width: `${vipProgress}%` }}></div>
            </div>
            <p>{vipText}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
